import WebApiService                                        from './web-api-service';
import packageJson from "../../package.json";	

const LOCAL_STORAGE_KEY = 'token';
const LOCAL_STORAGE_USER = 'user';
const LOCAL_STORAGE_TIMEOUT = 'sessionTimeout';
const LOCAL_STORAGE_USERDETAILS = 'userDetails';
const LOCAL_STORAGE_USEREXTERNALPRIVILEGES = 'userExternalPrivileges';
const LOCAL_STORAGE_SSOTIMEOUT = 'ssoTimeout';
const LOCAL_STORAGE_SSODATETIMEOUT = 'ssoDateTimeout';
const LOCAL_STORAGE_USERNAME = 'username';
const LOCAL_STORAGE_SSOURL = 'ssourl';
const LOCAL_STORAGE_VIEWEDMATTERS = 'viewedMatters';
const LOCAL_STORAGE_NETWORKERROR = 'networkError';
const LOCAL_STORAGE_LENDERNAME = 'lendername';
const LOCAL_STORAGE_MATTERTYPERESTRICTIONS = 'matterTypeRestrictions';

class authService extends WebApiService {

    constructor(props) {
        super(props);

        this.useLocalStorage = (typeof localStorage !== 'undefined');
        this.subscribers = [];
        this.mustRedirect=null;
        this.token = null;
        this.user = null;
        this.sessionTimeout = null;
        this.hydrateStateWithLocalStorage();
        this.userDetails = [];
        this.userExternalPrivileges = [];
        this.viewedMatters = [];        
        this.networkError = null;
        this.lendername = null;
        this.matterTypeRestrictions = [];
    }
    
    hydrateStateWithLocalStorage(){
        for (let key in this) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
              // get the key's value from localStorage
              let value = localStorage.getItem(key);

                if(key==="user"){
                    try {
                        this.user = value;
                      } catch (e) {
                        // handle empty string
                        this.user = null;
                      }
                }
                if(key==="token"){
                    try {
                        this.token = value;
                      } catch (e) {
                        
                        // handle empty string
                        this.token = null;
                      }
                }
                if(key==="sessionTimeout"){
                    try {
                        this.sessionTimeout = value;
                      } catch (e) {
                        
                        // handle empty string
                        this.sessionTimeout = null;
                      }
                }
              // parse the localStorage string and setState
            }
          }
        }

    
    isLoggedIn() {
        return this.token !== null;
    }

    subscribe(cb) {
        this.subscribers.push(cb);
    }
    isUserAdminEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/IsUserAdminPrivileges";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }

    isUserDockPackEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/CanUserDownloadDocumentPackForMatter";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }

    isUserNewLoanInstructionEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/IsUserAbleToInstructNewLoans";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }
    isUserDischargeInstructionEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/IsUserAbleToInstructDischarges";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }
    isUserDischargePayoutEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/IsUserAbleToInstructDischargePayout";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }





    isUserCustodyCentreEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/IsCustodyEnabled";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }

    userCanDownloadCustodyFiles(){
        return new Promise((resolve,reject)=>{
            const url ="api/UserCanDownloadCustodyFiles";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }

    isUserAuthorisedToInstruct(){
        return new Promise((resolve,reject)=>{
            const url ="api/canUserInstruct";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    isUserCanGenralUpload(){
        return new Promise((resolve,reject)=>{
            const url ="api/canUserGeneralUpload";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    canUserViewTotalAmount(){
        return new Promise((resolve,reject)=>{
            const url ="api/CanUserViewTotalAmount";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        });
    }

    isUserRequiredMFA(){
        return new Promise((resolve,reject)=>{
            const url ="api/isMFARequired";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }


    isUserMobileMFAEnabled(){
        return new Promise((resolve,reject)=>{
            const url ="api/isMFAMobileEnabled";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    updateMFAMobilePrivilege(username) {
        return new Promise((resolve, reject) => {
            const url = "api/updateMFAMobilePriv";
           /*  const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    'UserId': userid,
                }
            }; */
            var options = new FormData();
            //options.append("UserId", userid);
        
            //let self = this;

            super.post( url, options ).then((response) => {
               // let json = AuthService.parseJson(response);
              //  resolve(json);
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });        
    }

    notifySubscribers() {
        this.subscribers.forEach((cb) => cb(this.isLoggedIn()));
    }
    createAccount(email, mobile, firstName, lastName){
        return new Promise((resolve,reject)=>{
            //const url = "api/register"
            const url = "api/newregister"
            var options = new FormData();
            options.append('email',email)
            options.append('mobile',mobile)
            options.append('firstname',firstName)
            options.append('surname',lastName)
            
            super.registerPost(url,options).then((response)=>{
                let json = authService.parseJson(response);
                resolve(json);
                this.setUser(json.User);
                this.setSessionTimeout(json.SessionTimeout);
                //this.setUserdetails(json.User.Email, json.User.UserTypeId);
                //this.setUsername(json.User.Name);
            },(err)=>{
                reject(err);
            })
        });
    }


    setToken(token) {
       
        this.token=token
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_KEY, token);

        }
    }

    setUser(user) {
        this.user=user;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_USER, user);
        }
    }

    setUserExternalPrivilegesMenu(externaluserprivileges){
        this.userExternalPrivileges=externaluserprivileges;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_USEREXTERNALPRIVILEGES, JSON.stringify(this.userExternalPrivileges));
        }
    }

    setUserMatterTypeRestriction(mattertyperestrictions){
        this.matterTypeRestrictions=mattertyperestrictions;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_MATTERTYPERESTRICTIONS, JSON.stringify(this.matterTypeRestrictions));
        }
    }

    setSessionTimeout(sessionTimeout){
        this.sessionTimeout=sessionTimeout;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_TIMEOUT, sessionTimeout);
        }
    }

    setSSOTimeout(ssoTimeout){
        this.ssoTimeout=ssoTimeout;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_SSOTIMEOUT, ssoTimeout);
        }
    }

    setSSODateTimeout(ssoDateTimeout){
        this.ssoDateTimeout=ssoDateTimeout;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_SSODATETIMEOUT, ssoDateTimeout);
        }
    }

    setUsername(username){
        this.username=username;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_USERNAME, username);
        }
    }

    setLenderName(lender){
        //alert(lender)
        this.lendername=lender; 
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_LENDERNAME, lender);
        }    
    }
    

    setUserdetails(email, usertypeid){
        //alert(user);
        this.userDetails=[{"username":email, "usertypeid":usertypeid}];
        //this.userDetails=[{"username":email}];
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_USERDETAILS, JSON.stringify(this.userDetails));
        }
    }

    setViewedMatters(viewedMatters){
        //alert(viewedMatters);
        this.viewedMatters=viewedMatters;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_VIEWEDMATTERS, JSON.stringify(this.viewedMatters));
        }
    }

    //setSSOUrl(lenderId, mortMgrId){	
        //setSSOUrl(userId){	
            setSSOUrl(url){	
                //alert("irene")
                //alert(url)
        //this.ssourl  = this.ssoGetSsoUrl(userId);	
        // this.ssoGetSsoUrl(userId).then((resp) =>(             
        //     this.ssourl  = resp.data.Content
        // ));
    //    new Promise((resolve, reject) => {
    //         const url = "api/ssoGetSsoUrl";
    //         //alert(token.token);
    //         //alert(token.userId)
            
    //         // var options = new FormData();
    //         // options.append("userid", userId);
    //         let self = this;

            // const options={
            //     'headers':{
            //         //'Authorization':token,
            //         'userid':userId,
            //     }
            // }
    //         // alert(url)
    //         // alert(options)

    //         super.get(url,options).then((response)=>{
    //             //alert(response)
    //             this.ssourl = resolve(response.data.Content);
    //         },(err)=>{
    //             reject(err);
    //         });
            
    //     });
    // super.get('api/ssoGetSsoUrl', options).then(
    //     response => {     
          
          //resolve(response);
         // this.ssourl = response.data.Content
         this.ssourl = url;
          //alert(this.ssourl)
          if (this.useLocalStorage) {	
            localStorage.setItem(LOCAL_STORAGE_SSOURL, this.ssourl);	
            // alert('ssourl')	
            // alert(this.ssourl)	
         }	
        // },
        // ()=>{
        //     //alert(this.ssourl)
        // if (this.useLocalStorage) {	
        //     localStorage.setItem(LOCAL_STORAGE_SSOURL, this.ssourl);	
        //     // alert('ssourl')	
        //     // alert(this.ssourl)	
        // }	
        // },
    //     err => {
    //       const message = err;
    //       console.log(message);
    //       reject(message);
    //     }
    //   );
    
        // if((lenderId == 11 || lenderId == 183))  //nano Lender	
        // {	
        //     this.ssourl='https://nanossouat.msanational.com.au/'; //UAT	
        //     //this.ssourl='https://nanosso.msanational.com.au/'; //PROD	
        // }
        // else if((lenderId == 19 || lenderId == 171))  //Judo Lender	
        // {	
        //     this.ssourl='https://judossouat.msanational.com.au/'; //UAT	
        //     //this.ssourl='https://nanosso.msanational.com.au/'; //PROD	
        // }
        // else if((lenderId == 16 || mortMgrId == 1639)) {	
        //     this.ssourl='https://tictocssouat.msanational.com.au/'; //UAT	
        //     //this.ssourl='https://tictocsso.msanational.com.au/'; //PROD	
        // }	
        //this.ssourl='https://tictocssouat.msanational.com.au/'; //UAT	
        //this.ssourl='https://tictocsso.msanational.com.au/'; //PROD	
       	// alert(this.ssourl)
        // if (this.useLocalStorage) {	
        //     localStorage.setItem(LOCAL_STORAGE_SSOURL, this.ssourl);	
        //     // alert('ssourl')	
        //     // alert(this.ssourl)	
        // }	
    }
    setNetworkError(error){
        this.networkError=error;
        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_NETWORKERROR, this.networkError);
        }
    }     
    

    removeToken() {
        this.token = null;
        this.user = null;
        this.sessionTimeout = null;
        this.ssoTimeout = null;
        this.ssoDateTimeout = null;
        this.username = null;
        this.userDetails = [];
        this.viewedMatters= [];
        this.userExternalPrivileges = [];
        this.ssourl = null;
        this.networkError = null;
        this.matterTypeRestrictions = [];

        var a = new Promise((resolve, reject) => {

            const url = "api/logout";
            var options = new FormData();
            //options.append('secret_key','jeremydidagreatjob')
            //window.alert(this.getToken());
            super.post_with_token( url, this.getToken() ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
        if (this.useLocalStorage) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            localStorage.removeItem(LOCAL_STORAGE_USER);
            localStorage.removeItem(LOCAL_STORAGE_TIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_SSOTIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_SSODATETIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_USERNAME);
            localStorage.removeItem(LOCAL_STORAGE_SSOURL);
            localStorage.removeItem(LOCAL_STORAGE_USERDETAILS);
            localStorage.removeItem(LOCAL_STORAGE_VIEWEDMATTERS);
            localStorage.removeItem(LOCAL_STORAGE_USEREXTERNALPRIVILEGES);
            localStorage.removeItem(LOCAL_STORAGE_NETWORKERROR);            
            localStorage.removeItem(LOCAL_STORAGE_LENDERNAME);
            localStorage.removeItem(LOCAL_STORAGE_MATTERTYPERESTRICTIONS);
        }
       
    }

    SSOremoveToken() {
        this.token = null;
        this.user = null;
        this.sessionTimeout = null;
        this.ssoTimeout = null;
        this.ssoDateTimeout = null;
        this.username = null;
        this.userDetails = [];
        this.viewedMatters= [];
        this.userExternalPrivileges = [];
        this.ssourl = null;
        this.networkError = null;
        this.matterTypeRestrictions = [];

        var a = new Promise((resolve, reject) => {

            const url = "api/ssologout";
            var options = new FormData();
            //options.append('secret_key','jeremydidagreatjob')
            //window.alert(this.getToken());
            super.post_with_token( url, this.getToken() ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
        if (this.useLocalStorage) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            localStorage.removeItem(LOCAL_STORAGE_USER);
            localStorage.removeItem(LOCAL_STORAGE_TIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_SSOTIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_SSODATETIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_USERNAME);
            localStorage.removeItem(LOCAL_STORAGE_SSOURL);
            localStorage.removeItem(LOCAL_STORAGE_USERDETAILS);
            localStorage.removeItem(LOCAL_STORAGE_VIEWEDMATTERS);
            localStorage.removeItem(LOCAL_STORAGE_USEREXTERNALPRIVILEGES);
            localStorage.removeItem(LOCAL_STORAGE_NETWORKERROR); 
            localStorage.removeItem(LOCAL_STORAGE_LENDERNAME);   
            localStorage.removeItem(LOCAL_STORAGE_MATTERTYPERESTRICTIONS);         
        }
       
    }

    removeNetworkError(){
        if (this.useLocalStorage) {	           
            localStorage.removeItem(LOCAL_STORAGE_NETWORKERROR);
        }	
    }

    clearStorage(){	
        this.token = null;
        this.user = null;
        this.sessionTimeout = null;
        this.ssoTimeout = null;
        this.ssoDateTimeout = null;
        this.username = null;
        this.userDetails = [];
        this.viewedMatters= [];
        this.userExternalPrivileges = [];
        this.ssourl = null;
        this.networkError = null;
        this.matterTypeRestrictions = [];

        var a = new Promise((resolve, reject) => {

            const url = "api/logout";
            var options = new FormData();
            //options.append('secret_key','jeremydidagreatjob')
            //window.alert(this.getToken());
            super.post_with_token( url, this.getToken() ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
        if (this.useLocalStorage) {	
            localStorage.removeItem(LOCAL_STORAGE_KEY);	
            localStorage.removeItem(LOCAL_STORAGE_USER);	
            localStorage.removeItem(LOCAL_STORAGE_TIMEOUT);	
            localStorage.removeItem(LOCAL_STORAGE_SSOTIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_SSODATETIMEOUT);
            localStorage.removeItem(LOCAL_STORAGE_USERNAME);
            localStorage.removeItem(LOCAL_STORAGE_SSOURL);
            localStorage.removeItem(LOCAL_STORAGE_USERDETAILS);
            localStorage.removeItem(LOCAL_STORAGE_VIEWEDMATTERS);
            localStorage.removeItem(LOCAL_STORAGE_USEREXTERNALPRIVILEGES);
            localStorage.removeItem(LOCAL_STORAGE_NETWORKERROR);
            localStorage.removeItem(LOCAL_STORAGE_LENDERNAME);
            localStorage.removeItem(LOCAL_STORAGE_MATTERTYPERESTRICTIONS);            
        }	
    }

    requestUserPasswordCode(username, mobile) {
        return new Promise((resolve, reject) => {

            const url = "api/sendForgottenPasswordCode";
            var options = new FormData();
            options.append("username",username);
            options.append("mobile",mobile);
           
            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }

    requestUserVerificationCode(username) {
        return new Promise((resolve, reject) => {

            const url = "api/sendVerificationCode";
            var options = new FormData();
            options.append("username",username);
            //options.append("mobile",mobile);
           
            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }

    resendUserVerificationCode(username) {
        return new Promise((resolve, reject) => {

            const url = "api/resendVerificationCode";
            var options = new FormData();
            options.append("username",username);
            //options.append("mobile",mobile);
           
            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }

    resendUserAuthenticationCode(username) {
        return new Promise((resolve, reject) => {

            const url = "api/resendUserAuthenticationCode";
            var options = new FormData();
            options.append("username",username);
            //options.append("mobile",mobile);
           
            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }

    verifyBrokerCode(username, code) {
        return new Promise((resolve, reject) => {

            const url = "api/verifyBrokerCode";
            var options = new FormData();
            options.append("username",username);
            options.append("code",code);
           
            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }

    // deleteBrokerCode(username) {
    //     return new Promise((resolve, reject) => {

    //         const url = "api/deleteBrokerCode";
    //         var options = new FormData();
    //         options.append("username",username);
    //         //options.append("code",code);
           
    //         super.registerPost( url, options ).then((response) => {
    //             let json = authService.parseJson(response);
    //             resolve(json);
    //         }, (err) => {
    //             reject(err);
    //         });
    //     });
    // }



    


    static isTokenValid(token) { //not attempted, should check with back-end that token is still valid

        return new Promise(resolve => {
            return true;
        });
    }
    termsAcknowledge(username,password,accepted){
        return new Promise((resolve, reject) => {
        const url = "api/termsAcknowledged"

        var options = new FormData()
        options.append("username",username)
        options.append("password",password)
        options.append("accepted",accepted)
        let self = this;
        super.registerPost( url, options ).then((response) => {
                authService.checkStatus(response);
                let json = authService.parseJson(response);
                
                if(json.MustRedirect!=true)
                { 
                    self.setToken(json.SecurityToken);
                    self.setUser(json.User);
                    self.setSessionTimeout(json.SessionTimeout);
                    self.setUserdetails(json.User.Email, json.User.UserTypeId);
                    self.setUsername(json.User.Name);
                    if(json.User.UserTypeId == 2)  self.setLenderName(json.User.LenderName);
                }
                resolve(json);
            }, (err) => {
                reject(err);
            });
        });
    }
    // Login with username and password
    login(username, password) {
        return new Promise((resolve, reject) => {
            const url = "api/Login";
            var options = new FormData();
            options.append("username", username);
            options.append("password", password);
        
            let self = this;

            super.registerPost( url, options ).then((response) => {
                  authService.checkStatus(response);
                  let json = authService.parseJson(response);
                  //console.log(json);
                //  self.setToken(json.SecurityToken);
                //   alert(self.token);
                //   self.setUser(json.User);
                  
                  if(json.MustRedirect!=true && json.MustAuthenticate!=true)
                  { 
                    self.setToken(json.SecurityToken);
                    self.setUser(json.User);
                    self.setSessionTimeout(json.SessionTimeout);
                    self.setUserdetails(json.User.Email, json.User.UserTypeId);
                    self.setUsername(json.User.Name);
                    if(json.User.UserTypeId == 2)  self.setLenderName(json.User.LenderName);

                    //this.getUserExternalPrivilegesList();
                  }
                  resolve(json);
              }, (err) => {
                this.setNetworkError(err);
                if(err.response.data.Message)
                {
                    reject(err.response.data.Message)
                }
                else
                {
                    reject(err);
                }
              });
        });
    }

    ssologin(token,userId) {
        return new Promise((resolve, reject) => {
            const url = "api/SSOLogin";
            //alert(token.token);
            //alert(token.userId)
            
            var options = new FormData();
            // options.append("bearertoken", token.token);
            // options.append("userid", token.userId);
            options.append("bearertoken", token);
            options.append("userid", userId);
            //options.append("bearertoken", token);

            // alert(token);
            // alert(userId)
           
            let self = this;

            super.registerPost( url, options ).then((response) => {
                  authService.checkStatus(response);
                  let json = authService.parseJson(response);
                //  self.setToken(json.SecurityToken);
                //   alert(self.token);
                //   self.setUser(json.User);
                  
                  if(json.MustRedirect!=true && json.MustAuthenticate!=true)
                  { 
                    var dt = new Date();
                    //alert("today" + dt);
                    dt.setMinutes(dt.getMinutes()+json.SessionTimeout);
                    //self.setToken(token);
                    self.setToken(json.SecurityToken);
                    self.setUser(json.User);
                    self.setSessionTimeout(json.SessionTimeout);
                    self.setSSOTimeout(json.SessionTimeout);
                    self.setSSODateTimeout(dt);
                    self.setUsername(json.User.Name);
                    self.setUserdetails(json.User.Email, json.User.UserTypeId);
                    if(json.User.UserTypeId == 2)  self.setLenderName(json.User.LenderName);
                    //self.setSSOUrl(json.User.LenderId, json.User.MortManagerId);
                    //self.setSSOUrl(json.User.UserId);
                  }
                  resolve(json);
              }, (err) => {
                if(err.response.data.Message)
                {
                    reject(err.response.data.Message)
                }
                else
                {
                    reject(err);
                }
              });
        });
    }

    ssoGetSsoUrl(userId) {
        //alert(userId);
        return new Promise((resolve, reject) => {
            const url = "api/ssoGetSsoUrl";
            var token = '';
            if (userId == 0 || userId == "" || userId===undefined){
                token = this.getToken();
            }
            
            //alert(token);
            //alert(token.userId)
            
            // var options = new FormData();
            // options.append("userid", userId);
            let self = this;

            const options={
                'headers':{
                    'Authorization':token,
                    'userid':userId,
                }
            }
            // alert(url)
            // alert(options)

            super.get(url,options).then((response)=>{
                //alert(response)
                resolve(response);
                //this.setSSOUrl(userId);
                this.setSSOUrl(response.data.Content);
            },(err)=>{
                reject(err);
            });
            
        });
    }
    

    authenticateUser(username,password,code){
        return new Promise((resolve,reject)=>{
            const url = "api/authenticateuser";
            var body = new FormData();
            body.append("username",username);
            body.append("password",password);
            body.append("code",code);
            let self = this;

            super.registerPost(url,body).then((response)=>{
                authService.checkStatus(response);
                let json = authService.parseJson(response);
                self.setToken(json.SecurityToken);
              //   alert(self.token);
              //   self.setUser(json.User);
                if(json.MustRedirect!=true && json.MustAuthenticate!=true)
                { 
                  self.setToken(json.SecurityToken);
                  self.setUser(json.User);
                  self.setSessionTimeout(json.SessionTimeout);
                  self.setUserdetails(json.User.Email, json.User.UserTypeId);
                  self.setUsername(json.User.Name);
                  if(json.User.UserTypeId == 2)  self.setLenderName(json.User.LenderName);
                }
                resolve(json);
            }, (err) => {
              if(err.response.data.Message)
              {
                  reject(err.response.data.Message)
              }
              else
              {
                  reject(err);
              }
            });
            })
    }
    getToken(){
        return localStorage.getItem('token')
    }
    getUser(){
        return localStorage.getItem('user')
    }
    getUsername(){
        return localStorage.getItem('username')
    }
    getUserDetails(){
        //return localStorage.getItem('userDetails')
        return JSON.parse(localStorage.getItem('userDetails'))
        //alert(this.userDetails);
        //return this.userDetails;
    }
    getLendername()
    {
        //alert(this.lendername)
        //return this.lendername;
        return localStorage.getItem('lendername')
    }
    getViewedMatters(){
        //alert(this.viewedMatters);
        return JSON.parse(localStorage.getItem('viewedMatters'))
    }
    getSSOUrl(){
        return localStorage.getItem('ssourl')
    }
    getUserExternalPrivilegesMenu(){
        //console.log(JSON.parse(localStorage.getItem('userExternalPrivileges')));
        return JSON.parse(localStorage.getItem('userExternalPrivileges'))       
    }
    getUserTypeRestrictions(){
        //console.log(JSON.parse(localStorage.getItem('userExternalPrivileges')));
        return JSON.parse(localStorage.getItem('matterTypeRestrictions'))       
    }

    getNetworkError(){
        //alert(localStorage.getItem('networkError'))
        return localStorage.getItem('networkError')
    }

    changePassword(username, password, code) {

        return new Promise((resolve, reject) => {
            
            const url = "api/resetForgottenUserPassword";
            var options = new FormData();
            options.append('username', username)
            options.append('password', password)
            options.append('code', code)
            super.registerPost( url, options ).then((response) => {
                let json =authService.parseJson(response);
                //console.log(json)
                if(!json.MustAuthenticate){
                this.setToken(json.SecurityToken);
                this.setUser(json.User);
                this.setSessionTimeout(json.SessionTimeout);
                this.setUserdetails(json.User.Email, json.User.UserTypeId);
                this.setUsername(json.User.Name);
                }
                resolve(json);
            }, (err) => {
              
                reject(err);
            });
        });
    }
    // Logout
    logout() {
        this.removeToken();
    }

    SSOlogout() {
        this.SSOremoveToken();
    }

    isNewVersionDetected(){  
        let today = new Date();
        // if(localStorage.getItem("sessionTimeout") == 0 && localStorage.getItem("sessionTimeout") == null){
        //     localStorage.setItem("version", "0");
        // }
        console.log(localStorage.getItem("version")); 


        // fetch("/meta.json")	
        //         .then((response) => response.json())	
        //         .then((meta) => {	
                const latestVersion = packageJson.buildDate;		
                const currentVersion = localStorage.getItem("version");

                if(latestVersion != currentVersion)
                {

                    console.log("latest: " + latestVersion)	
                    console.log("current: " + currentVersion);	
                             	
                        //alert("refresh");	
                        console.log("new version detected delete cache");	
                        //setIsLatestBuildDate(false);	
                        //refreshCacheAndReload();	
                        if (caches) {	
                            // Service worker cache should be clnpm starteared with caches.delete()	
                            caches.keys().then((names) => {	
                            for (const name of names) {	
                                caches.delete(name);	
                            }	
                            });	
                            console.log("cache deleted");	
                        }
                        localStorage.setItem("version", latestVersion);	
                        console.log("new version " +localStorage.getItem("version")); 
                        //this.removeToken();	
                        // delete browser cache and hard reload
                        //window.location.reload(true);      
                        
                        
                        
                } else {	
                    //alert("no new version");	
                    console.log("no new version");	
                    //setIsLatestBuildDate(true);	
                    //window.location.reload();	
                    
                }	
        
    }

    // Confirm that the response is 200 or 300
    static checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error = new console.error(`HTTP Error ${response.statusText}`);
            error.status = response.statusText;
            error.response = response;
            console.log(error);
            throw error;
        }
    }

    getUserExternalPrivilegesList() {
        //console.log(AuthService.token)
        return new Promise((resolve, reject) => {

            const url = "api/getUserExternalPrivilegesList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);                
            }, (err) => {
                reject(err);
            });
        });
    }

    getUserBrokerExternalPrivilegesList() {
        //console.log(AuthService.token)
        return new Promise((resolve, reject) => {

            const url = "api/getUserBrokerExternalPrivilegesList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);                
            }, (err) => {
                reject(err);
            });
        });
    }

    CanAutoViewMatterOnUpload(){
        return new Promise((resolve,reject) =>{
            const url ="api/canAutoViewMatterOnUpload";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    GetLenderDetails() {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderDetails";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    GetLenderInfo() {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderInfo";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getUserType(username) {
        return new Promise((resolve, reject) => {

            const url = "api/getUserType";
            var options = new FormData();
            options.append("username",username);
            //options.append("mobile",mobile);
            //alert(options.username);
           
            // super.get( url, options ).then((response) => {
            //     resolve(response);
            // }, (err) => {
            //     reject(err);
            // });

            super.registerPost(url,options).then((response)=>{
                let json = authService.parseJson(response);
                resolve(json);
            },(err)=>{
                reject(err);
            })
        });
    }


    // Get the Json object from the response
    static parseJson(response) {
        // alert(response.data);
        return response.data;
    }

    getVersion() {

        return new Promise((resolve, reject) => {

            const url = "api/getVersion";
            const options = {
                "headers": {
                
                    
                }
            };

            super.get( url, options ).then((response) => {
                  resolve(response);
                  this.removeNetworkError(null);
              }, (err) => {
                  //alert(err)
                  this.setNetworkError(err);
                  reject(err + ' - ' + url);
              });
        });
    }

    logUserActivity(userActivity) {

        return new Promise((resolve, reject) => {

            const url = "api/logUserActivity";
            var options = new FormData();
            options.append('Authorization', AuthService.token)
            options.append('activity',userActivity)
            //options.append('username',username)
            // const options = {
            //     "headers": {
            //         'Authorization': AuthService.token,
            //         "activity": userActivity,
            //         "username": username,
            //         //"responseType": "application/json"
            //     }
            // };

            super.registerPost( url, options ).then((response) => {
                let json = authService.parseJson(response);
                resolve(json);
                console.log("loguseractivity");
                console.log(json);
                if(json.IsSuccessful==true)
                { 
                    this.setToken(json.SecurityToken);
                }
            }, (err) => {
                reject(err);
            });
        });
    }

    checkIfSessionHasExpired(strToken) {

        return new Promise((resolve, reject) => {

            const url = "api/checkIfSessionHasExpired";
            // var options = new FormData();
            // options.append('Authorization', AuthService.token)

            const options = {
                "headers": {
                    'Authorization': strToken,//AuthService.token,
                    "responseType": "application/json"
                }
            };

            //options.append('username',username)
            // const options = {
            //     "headers": {
            //         'Authorization': AuthService.token,
            //         "activity": userActivity,
            //         "username": username,
            //         //"responseType": "application/json"
            //     }
            // };

            super.get(url,options).then(	
                (response)=>{	
                    resolve(response);	
                },	
                (err)=>{	
                    reject(err);	
                }	
            )	
        });
    }

    CheckUserHasMatterTypeRestriction() {
        //console.log(AuthService.token)
        return new Promise((resolve, reject) => {

            const url = "api/checkUserHasMatterTypeRestriction";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);                
            }, (err) => {
                reject(err);
            });
        });
    }

    GetUserMatterTypeRestriction() {
        //console.log(AuthService.token)
        return new Promise((resolve, reject) => {

            const url = "api/getUserMatterTypeRestriction";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);                
            }, (err) => {
                reject(err);
            });
        });
    }
    

}

export const AuthService = new authService();
