import React, { Component } from 'react'
import { AuthService } from '../../../services/auth-service';
 
import IconBox from '../../atom/IconBox/IconBox';

import LogoMenu from '../../molecules/LogoMenu/LogoMenu'; 
import Line from '../../atom/Line/Line';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';

import PropTypes                                            from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom';
import { Redirect }                                         from 'react-router-dom';
 

import './css/LeftMenu.css';

import ButtonGroupCap from '../../molecules/ButtonGroupCap/ButtonGroupCap';

class LeftMenu extends Component {

    constructor(props){
        super(props);

        let matterrestrictions = AuthService.getUserTypeRestrictions();//AuthService.GetUserMatterTypeRestriction();
        let userprivileges = AuthService.getUserExternalPrivilegesMenu();
        let userdetails = AuthService.getUserDetails();
        
        this.state={
            dashboardCss: 'ButtonIconSelected',
            dischargesCss: '',
            searchCss: '',
            instructCss: '',
            custodyCss: '',
            adminCss: '',
            resourcesCss: '', 
            consentsCss: '',
            insightsCss: '',
            logout: false,
            currentPage: "",
            instrucmsaSubMenuStatus: this.props.instrucmsaSubMenuStatus,
            lmwInstructMSASub: this.props.lmwInstructMSASub,
            desktopOpenedSubMenu: this.props.desktopOpenedSubMenu,
            isInstructPriv:false,
            isCustodyPriv:false,
            isAdminPriv:false,
            username: null,
            CanInstructNewLoans: null,
            CanInstructDischarges: null,
            CanProvideDischargePayout: null,
            CanInstructDischargeUpload: null,
            CanViewLoanStat: null,
            //CanViewTotalLoanAmount: null,
            CanInstructNewLoansForm: null,
            CanInstructNewLoansUpload: null,
            userprivileges : userprivileges,
            userdetails:userdetails,
            ssologout: false,
            isMFARequired: false,
            isMFAMobileEnabled: false,
            MFAOption: 'email',
            hasError: false,
            RequiredMFA: null,
            MobileMFA: null,
            externalPrivileges: [],
            matterrestrictions: matterrestrictions,
            userHasMatterTypeRestriction:false,
            UserHasNewLoansRestriction:false,
            UserHasDischargesRestriction: false,
            UserHasConsentsRestriction: false,
            menuUrl: ''
        }
        this.handleStatusCallback = this.handleStatusCallback.bind(this); 
        this.changePage = this.changePage.bind(this);
        this.logout = this.logout.bind(this);
        this.ssologout = this.ssologout.bind(this);
        //this.SaveUserHandler = this.SaveUserHandler.bind(this);  
        
    }

    static contextTypes = {
        router: PropTypes.object
    }

    handleStatusCallback(child){
        //alert(child);
        this.setState({
            MFAOption: child
        },()=>
        {
            var brokerprivs = [];
            brokerprivs.push({"RequireMFA":true, "MobileMFA": child=="sms"?true:false});
            //update external priv
             //check if user is Required for MFA Mobile
            AuthService.updateMFAMobilePrivilege().then((resp)=>{
            this.setState({
                hasError: false,
                externalPrivileges: brokerprivs
                //RequiredMFA: this.state.RequiredMFA == true ? false : true
            },()=>{
                AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                // console.log('isUserMobileMFAEnabled')
                // console.log(this.state.isMFAMobileEnabled)
                // console.log(this.state.MFAOption)
            });
        },(err)=>{
            this.setState({isMFAMobileEnabled:false, hasError:true});
        });

        });
        // this.props.callBack(child, this.state.desktopMenu);
    }


    componentDidMount(){
        //alert('fdasf')
        //alert('leftmenu')
        //alert("leftmenu" + this.props.page);
        //check if user has matter type restrictions
        // AuthService.CheckUserHasMatterTypeRestriction().then(
        //     (resp)=>{
        //       this.setState({userHasMatterTypeRestriction:resp.data.Content})
        //     },
        //     (err)=>{
        //       this.setState({userHasMatterTypeRestriction:false})
        //     }
        // );


    //     let matterrestrictions1= [];//this.state.matterrestrictions;
    //     matterrestrictions1.push(this.state.matterrestrictions[0]);
    //     //this.state.matterrestrictions.map() ;
    //     console.log("ireneirene"+matterrestrictions1)
        
    //     var username = AuthService.getUsername();	
    //      let matterrestrictions= [1,2]//this.state.matterrestrictions;
    //    // matterrestrictions.push(this.state.matterrestrictions);
        // this.setState({	
        //     username: username,
        //     userHasNewLoansRestriction: matterrestrictions.includes(1)?true:false,
        //     userHasDischargesRestriction: matterrestrictions.includes(2)?true:false,
        //     userHasConsentsRestriction: matterrestrictions.includes(3)?true:false,
        // }
        console.log(this.state.matterrestrictions)	
        var username = AuthService.getUsername();	
        var matterrestrictions = AuthService.getUserTypeRestrictions();
        // let userHasNewLoansRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.userHasNewLoansRestriction) : null;
        // let userHasDischargesRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasDischargesRestriction) : null;
        // let userHasConsentsRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasConsentsRestriction) : null;
        
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        
        
        this.setState({	
            username: username,
            UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
            UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
            //menuUrl: 'dashboard'
            menuUrl: (userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "") ? 'discharges' :
                        (userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "") ? 'consents' :                         
                        (userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "") ? 'dashboard' : 'dashboard'
        }
        //,()=>{alert(this.state.username)}	
        ,()=>{
            console.log("userHasNewLoansRestriction1"+this.state.UserHasNewLoansRestriction);
            console.log("userHasDischargesRestriction1"+this.state.UserHasDischargesRestriction);
            console.log("userHasConsentsRestriction1"+this.state.UserHasConsentsRestriction);
            console.log("menuUrl"+this.state.menuUrl)
        }
        );
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2 || usertypeid == 3){
            //check user access
            let CanInstructNewLoans =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoans) : null;
            let CanInstructDischarges =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischarges) : null;
            let CanProvideDischargePayout =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanProvideDischargePayout) : null;
            let CanInstructDischargeUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischargeUpload) : null;
            let CanAdminSubUsers =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanAdminSubUsers) : null;
            let IsCustodyPrivilege =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.IsCustodyPrivilege) : null;  
            let CanViewLoanStat =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanViewLoanStat) : null;  
             
            //let CanViewTotalLoanAmount =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanViewTotalLoanAmount) : null;  
            let CanInstructNewLoansForm =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansForm) : null;   
            let CanInstructNewLoansUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansUpload) : null;
              

            this.setState({
                isInstructPriv:(CanInstructNewLoans[0]==true || CanInstructDischarges[0]==true || CanProvideDischargePayout[0]==true || CanInstructDischargeUpload[0]==true || CanInstructNewLoansForm[0]==true || CanInstructNewLoansUpload[0]==true)?true:false,
                CanInstructDischarges:CanInstructDischarges[0],
                CanProvideDischargePayout:CanProvideDischargePayout[0],
                CanInstructNewLoans:CanInstructNewLoans[0],
                CanInstructDischargeUpload:CanInstructDischargeUpload[0],
                isCustodyPriv:IsCustodyPrivilege[0],
                isAdminPriv:CanAdminSubUsers[0],
                CanViewLoanStat:CanViewLoanStat[0],
                CanInstructNewLoansForm:CanInstructNewLoansForm[0],
                CanInstructNewLoansUpload:CanInstructNewLoansUpload[0],
               
            });
        }
        if(usertypeid == 4){//broker
            let RequiredMFA = this.state.userprivileges != null? this.state.userprivileges.map(d=>d.RequireMFA) : null;
            let MobileMFA = this.state.userprivileges != null? this.state.userprivileges.map(d=>d.MobileMFA) : null;
            this.setState({
                RequiredMFA:RequiredMFA[0],
                MobileMFA:MobileMFA[0],
                MFAOption: MobileMFA[0]== true? 'sms' : 'email',
                CanViewLoanStat: true
            },()=>{
                //alert(this.state.RequiredMFA)
            });
        }

        //check if user is Required for MFA
        // AuthService.isUserRequiredMFA().then((resp)=>{
        //     this.setState({isMFARequired:resp.data.Content,},()=>{
        //         // console.log('isMFARequired')
        //         // console.log(this.state.isMFARequired)
        //         // console.log(resp)
        //     });
        // },(err)=>{
        //     this.setState({isMFARequired:false});
        // });


        //check if user is Required for MFA Mobile
        // AuthService.isUserMobileMFAEnabled().then((resp)=>{
        //     this.setState({
        //         //isMFAMobileEnabled:true,
        //         isMFAMobileEnabled: resp.data.Content,
        //         MFAOption: resp.data.Content == true? 'sms' : 'email'
        //     },()=>{
        //         // console.log('isUserMobileMFAEnabled')
        //         // console.log(this.state.isMFAMobileEnabled)
        //         // console.log(this.state.MFAOption)
        //     });
        // },(err)=>{
        //     this.setState({isMFAMobileEnabled:false});
        // });



        //alert(canInstruct);
        //let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';

        // AuthService.isUserAuthorisedToInstruct().then((resp)=>{
        //     this.setState({isInstructPriv:true});
        // },(err)=>{
        //     this.setState({isInstructPriv:false});
        // });
        // AuthService.isUserCustodyCentreEnabled().then((resp)=>{
        //     this.setState({isCustodyPriv:resp.data.Content});
        //     },(err)=>{
        //         this.setState({isCustodyPriv:false});
        // });
        // AuthService.isUserAdminEnabled().then((resp)=>{
        //     this.setState({isAdminPriv:resp.data.Content});
        // },(err)=>{
        //   this.setState({isAdminPriv:false});
        // });

        //get instructions privileges
        // AuthService.getUserExternalPrivilegesList().then((resp)=>{
        //     this.setState({
        //         CanInstructDischarges:resp.data.Content.CanInstructDischarges,
        //         CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
        //         CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
        //         CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
        //     },()=>{console.log("canirene");console.log(this.state.CanInstructNewLoans)});
        // },(err)=>{
        //     console.log("there is an error")
        //     //this.setState({isInstructPriv:false});
        // })


        this.setState({ 
            currentPage: this.props.page
        })

        if(this.props.page =='dashboard'){
            this.setState({
                dashboardCss: 'ButtonIconSelected',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }
        if(this.props.page =='discharges'){
            this.setState({
                dashboardCss: '',
                dischargesCss: 'ButtonIconSelected',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }
        if(this.props.page =='consents'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: 'ButtonIconSelected',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }
        if(this.props.page =='search'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: 'ButtonIconSelected',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }

        //if(this.props.page =='dischargeinstructions' || this.props.page =='dischargesupload' || this.props.page =='payoutupload'|| this.props.page =='newloansinstructions'){
        if(this.props.page =='dischargeinstructions' || this.props.page =='dischargesupload' || this.props.page =='payoutupload'|| this.props.page =='newloansinstructions' || this.props.page =="newloansinstructionsform"|| this.props.page =="newloansinstructionsupload"){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: 'ButtonIconSelected',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }

        if(this.props.page =='custodycentre'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: 'ButtonIconSelected',
                adminCss: '',
                resourcesCss: '',
                insightsCss: '',
            })
        }

        if(this.props.page =='admincontrol'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: 'ButtonIconSelected',
                resourcesCss: '',
                insightsCss: '',
            })
        }

        if(this.props.page =='resources'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: 'ButtonIconSelected',
                insightsCss: '',
            })
        }

        if(this.props.page =='insights'){
            this.setState({
                dashboardCss: '',
                dischargesCss: '',
                consentsCss: '',
                searchCss: '',
                instructCss: '',
                custodyCss: '',
                adminCss: '',
                resourcesCss: '',
                insightsCss: 'btnInsightsSelected',
            })
        }
    }

    static contextTypes = {
        router: PropTypes.object
    }

    // nextPath(path,arg){
    //     this.context.router.history.push(
    //         {pathname:path,
    //         state: {user: arg}})
    // }


    changePage(e, InstructSubMenu){
        //alert(InstructSubMenu);

        // this.setState({ 
        //     currentPage: e.target.id
        // })

        this.props.callBack(e.target.id, InstructSubMenu);

        // if(e.target.id == 'dashboard'  || this.props.page =='dashboard'){
        //     this.setState({
        //         dashboardCss: 'ButtonIconSelected',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'discharges'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: 'ButtonIconSelected',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'search' || this.props.page =='search'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: 'ButtonIconSelected',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'instructions'||this.props.page =='payoutupload'||this.props.page =='newloansinstructions'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: 'ButtonIconSelected',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'custodycentre'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: 'ButtonIconSelected',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'admincontrol'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: 'ButtonIconSelected',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'resources'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: 'ButtonIconSelected',
        //     })
        // }

    }

    nextPath(path,arg){
        this.context.router.history.push({
            pathname:path,
            state: {
                user: arg,

            }
        });
    }

    logout(){
        this.setState({
            
             logout: true
        })
    }
    ssologout(){
        this.setState({
            
             ssologout: true
        })
    }

    showSubMenu(e){
        //alert(e.target.id)
        var page=e.target.id;
        if(page=="instructions"){
            if(this.state.lmwInstructMSASub!="show"){
                this.setState({
                    lmwInstructMSASub: "show"
                });
            }else{
                this.setState({
                    lmwInstructMSASub: ""
                });
            }
        }
    }

    
    render() {
        let logout='';	
        if(localStorage.getItem("ssoTimeout") == null){	
            logout=(<ButtonIcon iconURL="../../../images/icons/OutlineDark/Sign_out.svg" onClick={this.logout} >Logout</ButtonIcon>);	
        }	
        if(localStorage.getItem("ssoTimeout") != null){	
            // logout=(	
            //     <ButtonIcon iconURL="../../../images/icons/OutlineDark/Sign_out.svg" onClick={() => this.nextPath('/sso-logout', null)}  >Logout</ButtonIcon>           	
            //  ); 	
            logout=(<ButtonIcon iconURL="../../../images/icons/OutlineDark/Sign_out.svg" onClick={this.ssologout} >Logout</ButtonIcon>);
        }

        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }
        
        if(this.state.logout == true){
            return(<Redirect to='/logout'></Redirect>)
        } 

        if(this.state.ssologout == true){
            return(<Redirect to='/sso-logout'></Redirect>)
        }

        let menuLink = this.state.menuUrl;
        // alert("s" + menuLink);
        // alert(this.state.username)

        return ( 
             
            <div className="LeftMenu">
                {menuLink != "" ? 
                    <LogoMenu toggleAction={this.props.toggleAction} menuUrl={menuLink} />  
                    :
                    ''
                    // <LogoMenu toggleAction={this.props.toggleAction} menuUrl="" />  
                }
                
                
                
                {/* <div className="userInfo">Hi, <strong>John Doe</strong></div> */} 
                <div className="userInfoBox">
                    <div className="userInfo">
                        <div>
                            Hi, <strong>{this.state.username} </strong> 
                            {/* {this.state.RequiredMFA && localStorage.getItem("ssoTimeout") == null ? 
                            (<div>
                                <br />
                                <ButtonGroupCap highlighted={this.state.MFAOption} callback={this.handleStatusCallback} firstOption="Email MFA" secondOption="SMS MFA" />    
                            </div>)
                            : ''} */}
                        </div>
                    </div>
                    {this.state.CanViewLoanStat?
                    <div>
                        <IconBox  
                            addCSS={"btnInsights " + this.state.insightsCss}
                            iconURL={this.state.insightsCss == ""?"../../../images/icons/OutlineDark/Insights.png":"../../../images/icons/OutlineWhite/Insights.png"}  
                            tooltip="Loan Insights"
                            mouseEnter={this.props.statBoxShow} 
                            clickAction={this.props.showInsightsDetails}
                        />
                    </div>
                    :
                    ''    
                    } 
                               
                </div>

                {this.state.RequiredMFA && localStorage.getItem("ssoTimeout") == null ? 
                    (<div> 
                        <ButtonGroupCap addCSS="mfaoption" highlighted={this.state.MFAOption} callback={this.handleStatusCallback} firstOption="Email MFA" secondOption="SMS MFA" />    
                    </div>)
                : ''}

                <div className='quickMenu'>
                   
                </div>



                {(this.state.UserHasNewLoansRestriction || this.state.UserHasDischargesRestriction || this.state.UserHasConsentsRestriction) ?
                    <>
                        {this.state.UserHasNewLoansRestriction?
                        <ButtonIcon 
                            iconURL={this.currentPage == "dashboard"|| this.props.page =="dashboard"?"../../../images/icons/OutlineWhite/Dashboard.svg":"../../../images/icons/OutlineDark/Dashboard.svg"}  addCSS={this.state.dashboardCss} 
                            id="dashboard" 
                            onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Dashboard</ButtonIcon>
                        :''}
                        
                        {this.state.UserHasDischargesRestriction?
                        <ButtonIcon iconURL={this.currentPage == "discharges"|| this.props.page =="discharges"?"../../../images/icons/OutlineWhite/Discharges.svg":"../../../images/icons/OutlineDark/Discharges.svg"} addCSS={this.state.dischargesCss} id="discharges" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Discharges</ButtonIcon>
                        :''}

                        {this.state.UserHasConsentsRestriction?
                        <ButtonIcon iconURL={this.currentPage == "consents"|| this.props.page =="consents"?"../../../images/icons/OutlineWhite/Discharge_Authorities.svg":"../../../images/icons/OutlineDark/Discharge_Authorities.svg"} addCSS={this.state.consentsCss} id="consents" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Consents</ButtonIcon>
                        :''}
                    </>
                    :
                    <>
                        <ButtonIcon 
                            iconURL={this.currentPage == "dashboard"|| this.props.page =="dashboard"?"../../../images/icons/OutlineWhite/Dashboard.svg":"../../../images/icons/OutlineDark/Dashboard.svg"}  addCSS={this.state.dashboardCss} 
                            id="dashboard" 
                            onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Dashboard</ButtonIcon>

                        <ButtonIcon iconURL={this.currentPage == "discharges"|| this.props.page =="discharges"?"../../../images/icons/OutlineWhite/Discharges.svg":"../../../images/icons/OutlineDark/Discharges.svg"} addCSS={this.state.dischargesCss} id="discharges" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Discharges</ButtonIcon>

                        <ButtonIcon iconURL={this.currentPage == "consents"|| this.props.page =="consents"?"../../../images/icons/OutlineWhite/Discharge_Authorities.svg":"../../../images/icons/OutlineDark/Discharge_Authorities.svg"} addCSS={this.state.consentsCss} id="consents" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                            lmwInstructMSASub={this.state.lmwInstructMSASub}
                        >Consents</ButtonIcon>
                    </>
                }                
                    
                <ButtonIcon iconURL={this.currentPage == "search"|| this.props.page =="search"?"../../../images/icons/OutlineWhite/Search.svg":"../../../images/icons/OutlineDark/Search.svg"} addCSS={this.state.searchCss} id="search" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                >Search Loans</ButtonIcon>
                {this.state.isInstructPriv?(
                    <ButtonIcon iconURL={this.currentPage == "dischargeinstructions"|| this.props.page =="dischargeinstructions" || this.currentPage == "dischargesupload"|| this.props.page =="dischargesupload" || this.currentPage == "payoutupload"|| this.props.page =="payoutupload"||this.currentPage == "newloansinstructions"|| this.props.page =="newloansinstructions"?"../../../images/icons/OutlineWhite/Diagonal_Arrow.svg":"../../../images/icons/OutlineDark/Diagonal_Arrow.svg"} addCSS={"lmwParent "+this.state.instructCss} id="instructions" onClick={(e)=>{this.showSubMenu(e)}}>Instruct MSA
                    <IconBox
                        id="instructions"
                        clickAction={(e)=>{this.showSubMenu(e)}}
                        //iconURL={"../../../images/icons/"+(this.currentPage == "dischargeinstructions"|| this.props.page =="dischargeinstructions" || this.currentPage == "dischargesupload"|| this.props.page =="dischargesupload" || this.currentPage == "payoutupload"|| this.props.page =="payoutupload"||this.currentPage == "newloansinstructions"|| this.props.page =="newloansinstructions"?"OutlineWhite":"OutlineDark")+"/"+(this.state.lmwInstructMSASub=="show"||this.state.desktopOpenedSubMenu=="instructions"?"Up":"Down")+".svg"}  
                        iconURL={"../../../images/icons/"+(this.currentPage == "dischargeinstructions"|| this.props.page =="dischargeinstructions" || this.currentPage == "dischargesupload"|| this.props.page =="dischargesupload" || this.currentPage == "payoutupload"|| this.props.page =="payoutupload"||this.currentPage == "newloansinstructions"|| this.props.page =="newloansinstructionsform"||this.props.page =="newloansinstructionsupload"?"OutlineWhite":"OutlineDark")+"/"+(this.state.lmwInstructMSASub=="show"||this.state.desktopOpenedSubMenu=="instructions"?"Up":"Down")+".svg"}  
                    />
                </ButtonIcon>
                ):''}
                {/* <ButtonIcon iconURL={this.currentPage == "instructions"|| this.props.page =="instructions"||this.currentPage == "payoutupload"|| this.props.page =="payoutupload"||this.currentPage == "newloansinstructions"|| this.props.page =="newloansinstructions"?"../../../images/icons/OutlineWhite/Diagonal_Arrow.svg":"../../../images/icons/OutlineDark/Diagonal_Arrow.svg"} addCSS={"lmwParent "+this.state.instructCss} id="instructions" onClick={(e)=>{this.showSubMenu(e)}}>Instruct MSA
                    <IconBox
                        iconURL={"../../../images/icons/"+(this.currentPage == "instructions"|| this.props.page =="instructions"||this.currentPage == "payoutupload"|| this.props.page =="payoutupload"||this.currentPage == "newloansinstructions"|| this.props.page =="newloansinstructions"?"OutlineWhite":"OutlineDark")+"/"+(this.state.lmwInstructMSASub=="show"||this.state.desktopOpenedSubMenu=="instructions"?"Up":"Down")+".svg"}  
                    />
                </ButtonIcon> */}
                <div className={"lmMenuSub "+(this.state.lmwInstructMSASub=="show"||this.state.desktopOpenedSubMenu=="instructions"?"":"none")}>
                    {this.state.CanInstructDischarges?(
                    <div 
                        id="dischargeinstructions" 
                        className={this.props.page =="dischargeinstructions"?"lmMenuSubSelected":""}
                        onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Discharge
                    </div>):''}
                    {this.state.CanInstructDischargeUpload?(
                    <div 
                        id="dischargesupload"
                        className={this.props.page =="dischargesupload"?"lmMenuSubSelected":""}
                         onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Discharge Upload
                    </div>):''}
                    {this.state.CanProvideDischargePayout?(
                    <div 
                        id="payoutupload"
                        className={this.props.page =="payoutupload"?"lmMenuSubSelected":""}
                        onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ Discharge Payout
                    </div>):''}
                    {this.state.CanInstructNewLoans?(
                    <div 
                        id="newloansinstructions"
                        className={this.props.page =="newloansinstructions"?"lmMenuSubSelected":""}
                         onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loan
                    </div>):''}
                    {this.state.CanInstructNewLoansForm?(
                    <div 
                        id="newloansinstructionsform"
                        className={this.props.page =="newloansinstructionsform"?"lmMenuSubSelected":""}
                        onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Form
                    </div>):''}
                    {this.state.CanInstructNewLoansUpload?(
                    <div 
                        id="newloansinstructionsupload"
                        className={this.props.page =="newloansinstructionsupload"?"lmMenuSubSelected":""}
                        onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Upload
                    </div>):''}
                </div>

                {this.state.isCustodyPriv?(<ButtonIcon iconURL={this.currentPage == "custodycentre"|| this.props.page =="custodycentre"?"../../../images/icons/OutlineWhite/Lock.svg":"../../../images/icons/OutlineDark/Lock.svg"} addCSS={this.state.custodyCss} id="custodycentre" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                    >Custody Centre</ButtonIcon>)
                    :''
                }

                {this.state.isAdminPriv?(<ButtonIcon iconURL={this.currentPage == "admincontrol"|| this.props.page =="admincontrol"?"../../../images/icons/OutlineWhite/User.svg":"../../../images/icons/OutlineDark/User.svg"} addCSS={this.state.adminCss} id="admincontrol" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>Admin Control</ButtonIcon>)
                :''
                }

                <ButtonIcon iconURL={this.currentPage == "resources"|| this.props.page =="resources"?"../../../images/icons/OutlineWhite/Star.svg":"../../../images/icons/OutlineDark/Star.svg"} addCSS={this.state.resourcesCss} id="resources" onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}
                lmwInstructMSASub={this.state.lmwInstructMSASub}>Resources</ButtonIcon>

                <Line />   
                
                {/* <ButtonIcon iconURL="../../../images/icons/OutlineDark/Sign_out.svg" onClick={this.logout} >Logout</ButtonIcon>   */}
                {logout}

            </div> 
        )
    }
}

export default LeftMenu
