import React, { Component } from 'react' 
 
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import IconBox from '../../atom/IconBox/IconBox';
import { Redirect }                                         from 'react-router-dom';

import './css/InsightBox.css';
import { AuthService } from '../../../services/auth-service';
import ButtonGroupCap from '../ButtonGroupCap/ButtonGroupCap';
import TitleSegment from '../../atom/TitleSegment/TitleSegment';
import TabButtonBox from '../TabButtonBox/TabButtonBox';
import Card from '../../atom/Card/Card';
import LoanStatus from '../../organism/LoanStatus/LoanStatus';
import DataTable from '../../organism/DataTable/DataTable';
import { MatterService } from '../../../services/matter-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class InsightBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            loanStatistics: [],
            hasError: false,     
            isLoading:false,        
        }

        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
      
    }
    componentDidMount(){       
        //alert(this.props.show)
        this.setState({
            isLoading: true,
            //InstructionSubmit:true,
        });       

        MatterService.getLoanStatisics().then((resp) => {
            let loanStat = resp.data.Content;
            console.log(loanStat)
            this.setState({
                loanStatistics: loanStat,
                isLoading: false
            })

            
        }, err => {
            this.setState({
                hasError: true
            })
        });
    }

    hideInsightBox(){
        this.props.callBackInsight();
    }

    moreInsights(){ 
        this.props.moreInsightCallback();
        //this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }
  
    render() {        
        return (
            <>
                {/* <div className={'InsightBox '+(this.props.show==1?"":"none ")}> */}
                <div className={'InsightBox '+(this.props.show==1?"":"none ")} onMouseLeave={() => this.hideInsightBox()} >

                    <IconBox 
                        addCSS="btnClose" 
                        iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                        tooltip="Close Insights"
                        clickAction={() => this.hideInsightBox()} 
                    />

                    {this.state.isLoading?<ProgressDisplay/>
                    :
                        <>
                        <TitleSegment>Loan Insights</TitleSegment>

                        {/* <div className='StatBar'>
                            <div className='SBLabel'>Stats</div>
                            <div className='SBData'>100</div>
                        </div> */}

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Settled Matters</div>
                            <div className='SBData'>{this.state.loanStatistics.SettledMatterCount}</div>
                        </div>

                        <div className='StatGroup'>
                            <div className='SGTitle'>Securities</div>
                            <div className='SGBar'>
                                <div className='SGLabel'>NSW</div>
                                <div className='SGData'>{this.state.loanStatistics.NSWSecurities}</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>VIC</div>
                                <div className='SGData'>{this.state.loanStatistics.VICSecurities}</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>QLD</div>
                                <div className='SGData'>{this.state.loanStatistics.QLDSecurities}</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>WA</div>
                                <div className='SGData'>{this.state.loanStatistics.WASecuritiesCount}</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>SA</div>
                                <div className='SGData'>{this.state.loanStatistics.SASecuritiesCount}</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>TAS</div>
                                <div className='SGData'>{this.state.loanStatistics.TASSecurities}</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>NT</div>
                                <div className='SGData'>{this.state.loanStatistics.NTSecurities}</div>
                            </div> 
                        </div> 

                        <div className='StatBar'>
                            <div className='SBLabel'>Average Days to Settlement</div>
                            <div className='SBData'>{this.state.loanStatistics.AverageDaysToSettlement}</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Settled using eSign Documents</div>
                            <div className='SBData'>{this.state.loanStatistics.SettledUsingEsignMatterCount}</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Escalated Files</div>
                            <div className='SBData'>{this.state.loanStatistics.EscalatedMatterCount}</div>
                        </div>

                        {/* <div className='StatAction'>
                            <ButtonIcon
                                id="insights"
                                addCSS="ButtonIconSelected biRight mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                onClick={()=>{this.moreInsights()}}
                            >More Insights</ButtonIcon>
                        </div>  */}
                        </>
                    }

                    

                </div>

            </> 
        )
    }
}

export default InsightBox
