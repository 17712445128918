import React, { Component } from 'react'
 
import IconLabelBox from '../../molecules/IconLabelBox/IconLabelBox'; 

import PropTypes                                            from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom';
import { Redirect }                                         from 'react-router-dom';
 
import './css/BottomMenu.css'; 
import { AuthService } from '../../../services/auth-service';

class BottomMenu extends Component {

    
    constructor(props){
        super(props);

        let userprivileges = AuthService.getUserExternalPrivilegesMenu();
        let userdetails = AuthService.getUserDetails();
        let matterrestrictions = AuthService.getUserTypeRestrictions();//AuthService.GetUserMatterTypeRestriction();
        this.state={ 
            logout: false,
            instrucmsaSubMenuStatus: this.props.instrucmsaSubMenuStatus,
            isInstructPriv:false,
            isCustodyPriv:false,
            isAdminPriv:false,
            username: null,
            CanInstructNewLoans: null,
            CanInstructDischarges: null,
            CanProvideDischargePayout: null,
            CanInstructDischargeUpload: null,
            CanInstructNewLoansForm: null,
            CanInstructNewLoansUpload: null,
            CanViewLoanStat: null,
            userprivileges : userprivileges,
            userdetails:userdetails,
            matterrestrictions: matterrestrictions,
            userHasMatterTypeRestriction:false,
            UserHasNewLoansRestriction:false,
            UserHasDischargesRestriction: false,
            UserHasConsentsRestriction: false,
        }

        this.logout = this.logout.bind(this); 
        
    } 

    static contextTypes = {
        router: PropTypes.object
    } 


    nextPath(path,arg){
        this.context.router.history.push({
            pathname:path,
            state: {
                user: arg, 
            }
        });
    }

    logout(){
        this.setState({ 
             logout: true
        })
    }

    showSubMenu($id){
        //console.log($id);
        var $btn=document.getElementById($id);
        var $btnCoordinates=$btn.getBoundingClientRect();
        var $btnXCoordinate=$btnCoordinates.left;
        var $subMenu=document.getElementById($id+"SubMenu"); 
        
        if($subMenu.style.display==="none"||$subMenu.style.display==""){
            $subMenu.style.left=$btnXCoordinate+"px";  
            $subMenu.style.display="block"; 
            $btn.classList.add("ilbShowSubMenu");
        }else{
            $subMenu.style.display="none"; 
            $btn.classList.remove("ilbShowSubMenu");
        } 
        
    }

    componentDidMount() {
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2 || usertypeid == 3){
        //check user access
            let CanInstructNewLoans =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoans) : null;
            let CanInstructDischarges =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischarges) : null;
            let CanProvideDischargePayout =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanProvideDischargePayout) : null;
            let CanInstructDischargeUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischargeUpload) : null;
            let CanAdminSubUsers =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanAdminSubUsers) : null;
            let IsCustodyPrivilege =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.IsCustodyPrivilege) : null;     
            let CanInstructNewLoansForm =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansForm) : null;   
            let CanInstructNewLoansUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansUpload) : null; 
            let CanViewLoanStat =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanViewLoanStat) : null;  

            this.setState({
                isInstructPriv:(CanInstructNewLoans[0]==true || CanInstructDischarges[0]==true || CanProvideDischargePayout[0]==true || CanInstructDischargeUpload[0]==true || CanInstructNewLoansForm[0]==true || CanInstructNewLoansUpload[0]==true)?true:false,
                CanInstructDischarges:CanInstructDischarges[0],
                CanProvideDischargePayout:CanProvideDischargePayout[0],
                CanInstructNewLoans:CanInstructNewLoans[0],
                CanInstructDischargeUpload:CanInstructDischargeUpload[0],
                isCustodyPriv:IsCustodyPrivilege[0],
                isAdminPriv:CanAdminSubUsers[0],
                CanInstructNewLoansForm:CanInstructNewLoansForm[0],
                CanInstructNewLoansUpload:CanInstructNewLoansUpload[0],
                CanViewLoanStat:CanViewLoanStat[0],
            });
        }

        if(usertypeid == 4){//broker
            this.setState({
                CanViewLoanStat: true
            });
        }

        var matterrestrictions = AuthService.getUserTypeRestrictions();
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        
        
        this.setState({	
            UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
            UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
        }
        //,()=>{alert(this.state.username)}	
        ,()=>{
            console.log("userHasNewLoansRestriction1"+this.state.UserHasNewLoansRestriction);
            console.log("userHasDischargesRestriction1"+this.state.UserHasDischargesRestriction);
            console.log("userHasConsentsRestriction1"+this.state.UserHasConsentsRestriction);
        }
        );
        // AuthService.isUserAuthorisedToInstruct().then((resp)=>{
        //     this.setState({isInstructPriv:true});
        // },(err)=>{
        //     this.setState({isInstructPriv:false});
        // });
        // AuthService.isUserCustodyCentreEnabled().then((resp)=>{
        //     this.setState({isCustodyPriv:resp.data.Content});
        //     },(err)=>{
        //         this.setState({isCustodyPriv:false});
        // });
        // AuthService.isUserAdminEnabled().then((resp)=>{
        //     this.setState({isAdminPriv:resp.data.Content});
        // },(err)=>{
        //   this.setState({isAdminPriv:false});
        // });

        //get instructions privileges
        // AuthService.getUserExternalPrivilegesList().then((resp)=>{
        //     this.setState({
        //         CanInstructDischarges:resp.data.Content.CanInstructDischarges,
        //         CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
        //         CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
        //         CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
        //     },()=>{console.log(this.state.CanInstructNewLoans)});
        // },(err)=>{
        //     console.log("there is an error")
        //     //this.setState({isInstructPriv:false});
        // })

        var $bmBox=document.querySelector(".bmBox");
        $bmBox.addEventListener("scroll", this.resetMenu);
        window.addEventListener("scroll", this.resetMenu);
        window.addEventListener("click", (e)=>this.outsideMenu(e)); 
    }

    outsideMenu(e){ 
        if(document.querySelector(".bmBox") != null){
            if(!document.querySelector(".bmBox").contains(e.target)){
                this.resetMenu();
            } 
        }
        
    }
    
    resetMenu(){
        for(var $btnAll of document.querySelectorAll(".IconLabelBox")){
            $btnAll.classList.remove("ilbShowSubMenu");
        }

        for(var $bmSubMenuAll of document.querySelectorAll(".bmSubMenu")){
            $bmSubMenuAll.style.display="none";
        }  
    }

    changePage(e, InstructSubMenu){  
        this.props.callBack(e.target.id, InstructSubMenu);
    }

    render() { 

        if(this.state.logout == true){
            return(<Redirect to='/logout'></Redirect>)
        }  

        return ( 
            <div className="BottomMenu">
                <div className="bmBox"> 
                {(this.state.UserHasNewLoansRestriction || this.state.UserHasDischargesRestriction || this.state.UserHasConsentsRestriction) ?
                <>
                    {this.state.UserHasNewLoansRestriction ?
                        <IconLabelBox
                            ilbLabel="Dashboard" 
                            addCSS={this.props.page=="dashboard"?"ilbSelected":""} 
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="dashboard"?"White":"Dark")+"/Dashboard.svg"} 
                            clickAction={()=>{this.props.callBack("dashboard")}}
                        />
                        :''}
                    
                        {this.state.UserHasDischargesRestriction ?
                        <IconLabelBox
                            ilbLabel="Discharges" 
                            addCSS={this.props.page=="discharges"?"ilbSelected":""}  
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="discharges"?"White":"Dark")+"/Discharges.svg"}  
                            clickAction={()=>{this.props.callBack("discharges")}}
                        />
                        :''}

                        {this.state.UserHasConsentsRestriction ?
                        <IconLabelBox
                            ilbLabel="Consents" 
                            addCSS={this.props.page=="consents"?"ilbSelected":""}  
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="consents"?"White":"Dark")+"/Discharge_Authorities.svg"}  
                            clickAction={()=>{this.props.callBack("consents")}}
                        />
                        :''}
                        </>
                    :
                        <>
                            <IconLabelBox
                                ilbLabel="Dashboard" 
                                addCSS={this.props.page=="dashboard"?"ilbSelected":""} 
                                iconURL={"../../../images/icons/Outline"+(this.props.page=="dashboard"?"White":"Dark")+"/Dashboard.svg"} 
                                clickAction={()=>{this.props.callBack("dashboard")}}
                            />
                            <IconLabelBox
                                ilbLabel="Discharges" 
                                addCSS={this.props.page=="discharges"?"ilbSelected":""}  
                                iconURL={"../../../images/icons/Outline"+(this.props.page=="discharges"?"White":"Dark")+"/Discharges.svg"}  
                                clickAction={()=>{this.props.callBack("discharges")}}
                            />
                            <IconLabelBox
                                ilbLabel="Consents" 
                                addCSS={this.props.page=="consents"?"ilbSelected":""}  
                                iconURL={"../../../images/icons/Outline"+(this.props.page=="consents"?"White":"Dark")+"/Discharge_Authorities.svg"}  
                                clickAction={()=>{this.props.callBack("consents")}}
                            />
                        </>
                    }                    
                    
                    <IconLabelBox 
                        ilbLabel="Search" 
                        addCSS={this.props.page=="search"?"ilbSelected":""} 
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="search"?"White":"Dark")+"/search.svg"}
                        clickAction={()=>{this.props.callBack("search")}}
                    />
                    
                    {this.state.isInstructPriv?(<IconLabelBox  
                        id="bmBTNInstructions"
                        ilbLabel="Instruct MSA"
                        addCSS={this.props.page=="instructions"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"?"ilbSelected":""}  
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="instructions"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"||this.props.page =="newloansinstructionsform"||this.props.page =="newloansinstructionsupload"?"White":"Dark")+"/Diagonal_Arrow.svg"}
                        clickAction={()=>this.showSubMenu("bmBTNInstructions")} 
                    />):''
                    }


                        <div id="bmBTNInstructionsSubMenu" className="bmSubMenu none">
                            {this.state.CanInstructDischarges?(
                            <div 
                                id="dischargeinstructions" 
                                className={this.props.page =="dischargeinstructions"?"selected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Discharge
                            </div>):''}
                            {this.state.CanInstructDischargeUpload?(
                            <div 
                                id="dischargesupload"
                                className={this.props.page =="dischargesupload"?"lmMenuSubSelected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Discharge Upload
                            </div>):''}
                            {this.state.CanProvideDischargePayout?(
                            <div 
                                id="payoutupload"
                                className={this.props.page =="payoutupload"?"selected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ Discharge Payout
                            </div>):''}
                            {this.state.CanInstructNewLoans?(
                            <div 
                                id="newloansinstructions"
                                className={this.props.page =="newloansinstructions"?"selected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loan
                            </div>):''}
                            {this.state.CanInstructNewLoans?(
                            <div 
                                id="newloansinstructionsform"
                                className={this.props.page =="newloansinstructionsform"?"selected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Form
                            </div>):''}
                            {this.state.CanInstructNewLoans?(
                            <div 
                                id="newloansinstructionsupload"
                                className={this.props.page =="newloansinstructionsupload"?"selected":""}
                                onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Upload
                            </div>):''}
                        </div>
                    
                    {this.state.isCustodyPriv?(<IconLabelBox
                        ilbLabel="Custody Center"  
                        addCSS={this.props.page=="custodycentre"?"ilbSelected":""} 
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="custodycentre"?"White":"Dark")+"/Lock.svg"}
                        clickAction={()=>{this.props.callBack("custodycentre")}}
                    />)
                    :''
                    }
                    
                    {this.state.isAdminPriv?(<IconLabelBox 
                        ilbLabel="Admin Control" 
                        addCSS={this.props.page=="admincontrol"?"ilbSelected":""} 
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="admincontrol"?"White":"Dark")+"/User.svg"}
                        clickAction={()=>{this.props.callBack("admincontrol")}}
                    />)
                    :''
                    }
                    
                    <IconLabelBox
                        ilbLabel="Resources" 
                        addCSS={this.props.page=="resources"?"ilbSelected":""}  
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="resources"?"White":"Dark")+"/Star.svg"} 
                        clickAction={()=>{this.props.callBack("resources")}}
                    />

                    <IconLabelBox
                        ilbLabel="Insights" 
                        addCSS={this.props.page=="insights"?"ilbSelected":""}  
                        iconURL={"../../../images/icons/Outline"+(this.props.page=="insights"?"White":"Dark")+"/Insights.png"} 
                        clickAction={()=>{this.props.callBack("insights")}}
                    />
 
                    
                    {/* <IconLabelBox
                        ilbLabel="Logout"  
                        iconURL="../../../images/icons/OutlineDark/Sign_out.svg"
                        clickAction={this.logout}
                        ></IconLabelBox> */}
                
                </div>
            </div> 
        )
    }
}

export default BottomMenu
