import React, { Component } from 'react'

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import HighlightText from '../../atom/HighlightText/HighlightText';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TextIcon from '../../molecules/TextIcon/TextIcon';
  
import DataTableUser from '../../organism/DataTableUser/DataTableUser';
import DataTablePaging from '../../organism/DataTablePaging/DataTablePaging';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent'; 

import './css/ResourcesPage.css';  
import SearchBox from '../../molecules/SearchBox/SearchBox';
import IconLabelBox from '../../molecules/IconLabelBox/IconLabelBox';
import AutoLogout from '../../../auto-logout';
import InsightBox from '../../molecules/InsightBox/InsightBox';

class ResourcesPage extends Component {

    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state = {
            pageTitle : 'resources',
            leftContent : 'resources',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            profileDisplay: "",
            InsightBox:0, 
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateWindowDimension)
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }


     
    render() {
        
        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}  
                />
            )
        }
 
        return ( 
             
            <LeftMenuContent 
                id="ResourcesPage" 
                page="resources" 
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                       

                    <div className="content"> 


                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
</div>

<div className='StatAction'>
    <ButtonIcon
        id="insights"
        addCSS="ButtonIconSelected biRight mainBtn"
        iconURL="../../../../images/icons/OutlineWhite/Next.svg"
        onClick={()=>{this.moreInsights()}}
    >More Insights</ButtonIcon>
</div> 

                        </div> */}


                        
                        <div>
                            <TitleSegment addCSS="titleDesktop">Resources</TitleSegment>

                            <div className="ResourcesBox">

                                <a href="http://www.msanational.com.au/downloads/MSA-Digitaldocs-Hints-11042018-advantedge.pdf" target="_blank">
                                <IconLabelBox
                                    addCSS="ilbSub ButtonIconBox"
                                    iconURL="../../../../images/icons/OutlineDark/Sign.svg"
                                    ilbLabel="E-Sign&#10;FAQ"
                                />
                                </a>

                                <a href="http://www.msanational.com.au/downloads/MSA-PEXA-Myths-30082018.pdf" target="_blank">
                                <IconLabelBox
                                    addCSS="ilbSub ButtonIconBox"
                                    iconURL="../../../../images/icons/OutlineDark/PEXA.svg"
                                    ilbLabel="PEXA&#10;FAQ"
                                />
                                </a>

                                <a href="http://www.msanational.com.au/FASTRefi.htm" target="_blank">
                                <IconLabelBox
                                    addCSS="ilbSub ButtonIconBox"
                                    iconURL="../../../../images/icons/OutlineDark/Fast_Refi.svg"
                                    ilbLabel="FastRefi&#10;FAQ"
                                />
                                </a>

                                <a href="http://www.msanational.com.au/broker-resources-FAQs.htm" target="_blank">
                                <IconLabelBox
                                    addCSS="ilbSub ButtonIconBox"
                                    iconURL="../../../../images/icons/OutlineDark/FAQ.svg"
                                    ilbLabel="General&#10;FAQ"
                                />
                                </a>

                                <a href="http://www.msanational.com.au/discharge-forms.htm" target="_blank">
                                <IconLabelBox
                                    addCSS="ilbSub ButtonIconBox"
                                    iconURL="../../../../images/icons/OutlineDark/Discharge_Authorities.svg"
                                    ilbLabel="Discharge&#10;Authorities"
                                />
                                </a>

                            </div>
                            
                             

                        </div> 

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}

ResourcesPage=AutoLogout(ResourcesPage);
export default ResourcesPage

